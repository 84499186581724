/**
 * init dotenv
 *
 * .env: Default.
 * .env.local: Local overrides. This file is loaded for all environments except test.
 * .env.development, .env.test, .env.production: Environment-specific settings.
 * .env.development.local, .env.test.local, .env.production.local: Local overrides of environment-specific settings.
 *
 * Available settings
 *
 * APP_PORT=9527
 * APP_BASE_PATH=/v1
 * APP_JWT_PUBLIC_KEY=`a public key string`
 */

/**
 *
 * @param {string} name envrionment name
 * @param {object} opt option with { required, default }
 * @returns {*} value
 */

function env(name, init) {
  const key = `REACT_APP_${name.toUpperCase()}`;
  const buildtimeValue = process && process.env && process.env[key];
  const runtimeValue = window && window._36node && window._36node[key];

  const value = runtimeValue || buildtimeValue || init;
  if (value === undefined) {
    throw new Error(`environment ${name} is missing`);
  }

  return value;
}

export const STORE_BASE = env("STORE_BASE", "");
export const AUTH_BASE = env(
  "AUTH_BASE",
  "https://api.stargate.36node.com/auth/v0"
);
export const AUTH_PROVIDER = env(
  "AUTH_PROVIDER",
  "5df461fccb1fd90011440a3e",
  true
);
export const CORE_BASE = env(
  "CORE_BASE",
  "https://api.catcm.36node.com/library/core/v0-uat"
);
export const OSS_URL_BASE = env(
  "OSS_URL_BASE",
  "https://36node-catcm.oss-cn-beijing.aliyuncs.com/library"
);

export const VERSION = env("VERSION", "");
export const ROOT_NS = env("ROOT_NS", "/catcm/library");

/* eslint-disable */
console.log(`VERSION:${VERSION}`);
console.log(`ROOT_NS:${ROOT_NS}`);
console.log(`STORE_BASE:${STORE_BASE}`);
console.log(`AUTH_BASE:${AUTH_BASE}`);

/* eslint-disable */
