import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { Menu, Icon, Dropdown } from "antd";
import { get } from "lodash";
import styled from "styled-components";
import { withSession } from "@36node/redux-session";

import { auth } from "../actions/api";
import { menu } from "../config";
import { getUser, getUserRole } from "../lib";
import { RoleTypes } from "../constants";

const adminKey = ["用户管理"];

const logout = auth.makeLogout("session");

const renderMenu = menu => {
  if (!Array.isArray(menu)) return;
  return menu.map(item =>
    item.children ? (
      <Menu.SubMenu
        key={item.title}
        title={
          <span>
            {item.icon && <Icon type={item.icon} />}
            {item.title}
          </span>
        }
      >
        {renderMenu(item.children)}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={item.path}>
        <NavLink to={item.path}>
          <span>
            {item.icon && <Icon type={item.icon} />}
            {item.title}
          </span>
        </NavLink>
      </Menu.Item>
    )
  );
};

@withRouter
@withSession("session")
@connect()
export default class Nav extends React.Component {
  handleLogout = () => {
    this.props.dispatch(
      logout({
        sessionId: get(this.props.session, "result.id"),
      })
    );
  };

  get user() {
    return getUser(this.props);
  }

  filterMenu = () => {
    const role = getUserRole(this.props);
    return role && role === RoleTypes.ADMIN
      ? menu
      : menu.filter(c => adminKey.indexOf(c.title) === -1);
  };

  userDropDown = () => (
    <Menu>
      <Menu.Item>
        <NavLink to="/profile">个人中心</NavLink>
      </Menu.Item>
      <Menu.Item onClick={this.handleLogout}>退出登录</Menu.Item>
    </Menu>
  );

  render() {
    const { pathname } = this.props.location;

    return (
      <NavBox>
        {this.user.id && (
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[pathname]}>
            {renderMenu(this.filterMenu())}
          </Menu>
        )}
        {this.user.id && (
          <Dropdown overlay={this.userDropDown()}>
            <div className="dropdown-link">
              {this.user.name || ""} <Icon type="down" />
            </div>
          </Dropdown>
        )}
        {!this.user.id && (
          <NavLink to="/login">
            <div className="dropdown-link">登录</div>
          </NavLink>
        )}
      </NavBox>
    );
  }
}

const NavBox = styled.div`
  display: flex;
  justify-content: flex-end;

  .user {
    color: #666;
  }

  .dropdown-link {
    color: white;
    cursor: pointer;
    margin-left: 24px;
    font-size: 16px;

    :hover {
      color: #eee;
    }
  }

  .ant-menu-dark.ant-menu-horizontal {
    background: none;

    > .ant-menu-item {
      line-height: 28px;
      margin-top: 18px;
      border-right: 1px solid white;
      border-color: rgba(255, 255, 255, 0.65);
      color: white;
      font-size: 16px;

      a {
        color: white;

        &:hover {
          color: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
`;
