import { makeApiTypes as mt } from "@36node/redux";

export const LOGIN = mt("LOGIN");
export const LOGOUT = mt("LOGOUT");
export const REFRESH = mt("REFRESH");
export const UN_AUTH = mt("UN_AUTH");

// auth user
export const AUTH_USER_LIST_USERS = mt("AUTH_USER_LIST_USERS");
export const AUTH_USER_CREATE_USER = mt("AUTH_USER_CREATE_USER");
export const AUTH_USER_UPDATE_USER = mt("AUTH_USER_UPDATE_USER");
export const AUTH_USER_DELETE_USER = mt("AUTH_USER_DELETE_USER");

// library
export const LIBRARY_LIST_LIBRARY = mt("LIBRARY_LIST_LIBRARY");
export const LIBRARY_CREATE_LIBRARY = mt("LIBRARY_CREATE_LIBRARY");
export const LIBRARY_GET_LIBRARY = mt("LIBRARY_GET_LIBRARY");

// permission
export const PERMISSION_LIST_PERMISSION = mt("PERMISSION_LIST_PERMISSION");
export const PERMISSION_UPSERT_PERMISSION = mt("PERMISSION_UPSERT_PERMISSION");
