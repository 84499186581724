import Auth from "@36node/auth-sdk";
import Core from "@36node/catcm-library-core-sdk";
import { AUTH_BASE, CORE_BASE } from "../config/env";

const token = () => sessionStorage.getItem("token");

export const auth = new Auth({
  base: AUTH_BASE,
  token,
});

export const core = new Core({
  base: CORE_BASE,
  token,
});
