import React from "react";
import { withSaga, tapOnLatest } from "@36node/redux";
import { Form, Icon, Input, Button, Checkbox, Layout, message } from "antd";
import styled from "styled-components";
import { createForm } from "@36node/redux-antd";
import { get } from "lodash";

import Center from "../../components/layout/center";
import { auth } from "../../actions/api";
import { AUTH_PROVIDER } from "../../config";
import { LOGIN } from "../../actions/types";

const FormItem = Form.Item;
const login = auth.makeLogin("session");

@createForm("login")
@withSaga(
  tapOnLatest(LOGIN.FAILURE, "session", function(action) {
    message.error("用户名或密码错误!");
  })
)
export default class Login extends React.PureComponent {
  handleSubmit = e => {
    e.preventDefault();
    const getfrom = get(this.props.location.state, "from.pathname", "/");
    const from = getfrom === "/login" ? "/" : getfrom;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(
          login(
            { body: { ...values, provider: AUTH_PROVIDER } },
            { from, remember: values.remember }
          )
        );
      }
    });
  };

  fileds = {
    Username: ({ initialValue }) =>
      this.props.form.getFieldDecorator("username", {
        initialValue,
        rules: [
          {
            required: true,
            message: "请输入用户名/邮箱!",
          },
        ],
      })(<Input prefix={<Icon type="user" />} placeholder="用户名/邮箱" />),
    Password: ({ initialValue }) =>
      this.props.form.getFieldDecorator("password", {
        initialValue,
        rules: [
          {
            required: true,
            message: "请输入密码!",
          },
        ],
      })(
        <Input
          prefix={<Icon type="lock" />}
          type="password"
          placeholder="密码"
        />
      ),
    RememberMe: ({ initialValue }) =>
      this.props.form.getFieldDecorator("remember", {
        initialValue,
        valuePropName: "checked",
      })(<Checkbox>自动登录</Checkbox>),
  };

  render() {
    const { Username, Password, RememberMe } = this.fileds;

    return (
      <LoginPage>
        <Center>
          <LoginBox>
            <div className="login-title">中医药传统典籍保护数据库</div>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem className="username">
                <Username />
              </FormItem>
              <FormItem className="password">
                <Password />
              </FormItem>
              <FormItem className="remember">
                <RememberMe initialValue={true} />
              </FormItem>
              <FormItem className="submit">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  登录
                </Button>
              </FormItem>
            </Form>
          </LoginBox>
        </Center>
        <div className="login-footer">
          Copyright© 2020 国家中医药管理局中医药传统知识保护研究中心
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="http://www.beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
            alt=""
          >
            京ICP备12038572号-3
          </a>
        </div>
      </LoginPage>
    );
  }
}

const LoginPage = styled(Layout)`
  background: url("/images/login-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;

  .login-footer {
    position: absolute;
    bottom: 24px;
    width: 100%;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
  }
`;

const LoginBox = styled("div")`
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .login-title {
    text-align: center;
    color: white;
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 48px;
  }

  .login-form {
    width: 400px;

    input {
      font-size: 16px;
      border-color: #1da57a;
    }

    .password {
      margin-bottom: 0;
    }

    .submit {
      margin-bottom: 0;
    }

    .ant-checkbox-wrapper {
      color: white;
    }

    .anticon {
      color: rgba(0, 0, 0, 0.25);
      font-size: 16px;
    }

    .ant-input {
      padding-left: 40px;
    }
  }

  .login-form-button {
    width: 100%;
    font-size: 16px;
  }
`;
