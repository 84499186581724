export const SESSION_ID = "session_id";
export const AUTO_LOGIN = "AUTO_LOGIN";
export const TOKEN = "token";
export const LOGIN_URL = "/login";

export const RoleTypes = {
  ADMIN: "ADMIN",
  USER: "USER",
};

export const CATEGORY = [
  "全部",
  "方书",
  "儿科",
  "本草",
  "温病旧导",
  "基础理论",
  "养生",
  "临证综合",
  "女科",
  "温病新传",
  "外科",
  "医案医话医论",
  "医经",
  "内科",
  "伤寒金匮",
  "综合性著作",
  "伤科",
  "诊法",
  "温病",
  "针灸推拿",
];
