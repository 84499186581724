import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const WebTitle = () => (
  <HeadTitle to="/home">中医药传统典籍保护数据库</HeadTitle>
);

/** @component */
export default WebTitle;

const HeadTitle = styled(NavLink)`
  color: white;
  font-size: 20px;
  font-weight: 500;

  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
`;
