import React from "react";
import { hot } from "react-hot-loader/root";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
import { Layout, ConfigProvider, Spin } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import styled from "styled-components";
import { history, ProtectedRoute, withSession } from "@36node/redux-session";
import { isEmpty } from "lodash";

import WebTitle from "./components/webTitle";
import Nav from "./containers/nav";
import Loading from "./components/loading";
import Login from "./containers/login";

const { Content, Header } = Layout;

const Books = Loadable({
  loader: () => import("./containers/books"),
  loading: Loading,
});
const Users = Loadable({
  loader: () => import("./containers/users"),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import("./containers/profile"),
  loading: Loading,
});
const Book = Loadable({
  loader: () => import("./containers/books/book-check"),
  loading: Loading,
});
const Search = Loadable({
  loader: () => import("./containers/search"),
  loading: Loading,
});

const App = () => (
  <ConfigProvider locale={zhCN}>
    <Router history={history}>
      <Switch>
        <Route path="/login" component={Login} />
        <ProtectedRoute redirect="/login" component={Main} />
      </Switch>
    </Router>
  </ConfigProvider>
);

const Main = withSession("session")(props => {
  const { result = {} } = props.session;

  if (isEmpty(result.user)) {
    return <Spin />;
  }

  return (
    <Switch>
      <Route path="/books" component={Books} />
      <Route path="/users" exact component={MainLayout} />
      <Route path="/profile" exact component={MainLayout} />
      <Route path="/search" exact component={MainLayout} />
      <Route path="/book/:id" component={MainLayout} />
      <Redirect from="/" to="/books" />
    </Switch>
  );
});

const MainLayout = () => (
  <Layout style={{ minHeight: "100vh" }}>
    <HeaderBox>
      <WebTitle />
      <Nav />
    </HeaderBox>
    <ContentBox>
      <Switch>
        <Route path="/users" exact component={Users} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/search" exact component={Search} />
        <Route path="/book/:id" component={Book} />
      </Switch>
      <div style={{ textAlign: "center", padding: "48px 0 0 0", fontSize: 14 }}>
        Copyright© 2020 国家中医药管理局中医药传统知识保护研究中心
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="http://www.beian.miit.gov.cn"
          target="_blank"
          rel="noopener noreferrer"
          alt=""
        >
          京ICP备12038572号-3
        </a>
      </div>
    </ContentBox>
  </Layout>
);

const HotApp = process.env.NODE_ENV === "development" ? hot(App) : App;
export default HotApp;

const HeaderBox = styled(Header)`
  display: felx;
  justify-content: space-between;
  background: #1da57a;
`;

const ContentBox = styled(Content)`
  background-image: url("/images/main-bg.jpg");
  background-position: center top;
  background-size: cover;
  padding: 24px;
`;
