import { createApiMaker } from "@36node/redux";

import * as types from "./types";
import * as sdk from "../sdk";
import { userSchema, librarySchema, permissionSchema } from "../schemas";

export const auth = {
  makeLogin: createApiMaker(types.LOGIN, sdk.auth.session.createSession),
  makeLogout: createApiMaker(types.LOGOUT, sdk.auth.session.deleteSession),
  makeRefresh: createApiMaker(types.REFRESH, sdk.auth.session.getSession),
};

export const user = {
  makeListUsers: createApiMaker(
    types.AUTH_USER_LIST_USERS,
    sdk.auth.user.listUsers,
    [userSchema]
  ),
  makeCreateUser: createApiMaker(
    types.AUTH_USER_CREATE_USER,
    sdk.auth.user.createUser,
    userSchema
  ),
  makeUpdateUser: createApiMaker(
    types.AUTH_USER_UPDATE_USER,
    sdk.auth.user.updateUser,
    userSchema
  ),
  makeDeleteUser: createApiMaker(
    types.AUTH_USER_DELETE_USER,
    sdk.auth.user.deleteUser
  ),
};

export const library = {
  makeListLibrary: createApiMaker(
    types.LIBRARY_LIST_LIBRARY,
    sdk.core.library.listLibrary,
    [librarySchema]
  ),
  makeCreateLibrary: createApiMaker(
    types.LIBRARY_CREATE_LIBRARY,
    sdk.core.library.createLibrary,
    librarySchema
  ),
  makeGetLibrary: createApiMaker(
    types.LIBRARY_GET_LIBRARY,
    sdk.core.library.showLibraryById,
    librarySchema
  ),
};

export const permission = {
  makeListPermission: createApiMaker(
    types.PERMISSION_LIST_PERMISSION,
    sdk.core.permission.listPermission,
    [permissionSchema]
  ),
  makeUpsertPermission: createApiMaker(
    types.PERMISSION_UPSERT_PERMISSION,
    sdk.core.permission.upsertPermission,
    [permissionSchema]
  ),
};
